<template>
  <ValidationObserver
    class="question multiple-answer-matrix column is-10"
    tag="div"
    v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <div class="is-desktop" v-if="!showMobileView">
          <template v-if="categories">
            <table class="table is-hidden-touch block-randomization">
              <colgroup>
                <col :key="index" :width="colWidth(index)" v-for="index in cols" />
              </colgroup>
              <tr class="border-0 sticky-header" >
                <td></td>
                <td
                  :key="answer.id"
                  v-for="answer in question.answers"
                  v-html="answer.answer[lang]"
                ></td>
              </tr>
              <template v-for="(category, index) in categories">
                  <tr class="category-title" v-if="category.display_title && category.sub_questions.length > 0" :key="`block-${index}`">
                    <td :colspan="question.answers.length + 1">{{ category.title[lang] || Object.values(category.title)[0] }}</td>
                  </tr>
                  <ValidationProvider
                    :key="subQuestion.id"
                    :rules="validationRules"
                    tag="tr"
                    v-for="subQuestion in category.sub_questions"
                    v-slot="{ errors }"
                  >
                    <td :class="{ 'has-text-danger has-text-weight-bold': errors[0] }">
                      <div v-html="subQuestion.question[lang]"></div>
                    </td>
                    <td :key="answer.id" v-for="answer in question.answers">
                      <b-field>
                        <b-checkbox
                          v-model="selected[subQuestion.code]"
                          @input="
                            onInput(question.qid, subQuestion.code, answer, $event)
                          "
                          :disabled="loading"
                          :native-value="answer.code"
                        />
                      </b-field>
                    </td>
                  </ValidationProvider>
              </template>
            </table>
          </template>
          <template v-else>
            <table class="table is-hidden-touch">
              <colgroup>
                <col :key="index" :width="colWidth(index)" v-for="index in cols" />
              </colgroup>
              <tr class="sticky-header">
                <td></td>
                <td
                  :key="answer.id"
                  v-for="answer in question.answers"
                  v-html="answer.answer[lang]"
                ></td>
              </tr>
              <ValidationProvider
                :key="subQuestion.id"
                :rules="validationRules"
                tag="tr"
                v-for="subQuestion in question.sub_questions"
                v-slot="{ errors }"
              >
                <td :class="{ 'has-text-danger has-text-weight-bold': errors[0] }">
                  <div v-html="subQuestion.question[lang]"></div>
                </td>
                <td :key="answer.id" v-for="answer in question.answers">
                  <b-field>
                    <b-checkbox
                      v-model="selected[subQuestion.code]"
                      @input="
                        onInput(question.qid, subQuestion.code, answer, $event)
                      "
                      :disabled="shouldDisable(questionKey(question.qid, subQuestion.code, answer.code))"
                      :native-value="answer.code"
                    />
                  </b-field>
                </td>
              </ValidationProvider>
            </table>
          </template>
        </div>

        <div class="is-mobile" :class="{'is-hidden-desktop' : !showMobileView, 'show-as-mobile-view': showMobileView}">
          <template v-if="categories">
            <div :key="index" v-for="(category, index) in categories" class="category-content">
              <p v-if="category.display_title && category.sub_questions.length > 0" class="category-title">{{ category.title[lang] || Object.values(category.title)[0] }}</p>
                <b-collapse
                  :ref="`collapse${getIndex(subQuestion._id)}`"
                  :key="`collapse-${getIndex(subQuestion._id)}`"
                  :open="isOpen === getIndex(subQuestion._id)"
                  @open="openCollapse(getIndex(subQuestion._id))"
                  animation="slide"
                  class="card mb-2"
                  v-for="(subQuestion) in category.sub_questions"
                >
                  <template #trigger="props">
                    <div
                      :class="{ active: props.open, 'has-error': hasError(getIndex(subQuestion._id)) }"
                      class="card-header"
                      role="button"
                    >
                      <div class="card-header-control">
                        <p
                          class="card-header-title"
                          v-html="subQuestion.question[lang]"
                        ></p>
                        <a class="card-header-icon">
                          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                          </b-icon>
                        </a>
                      </div>
                      <div
                        class="selected-option"
                        v-if="!props.open"
                      >
                        <div v-for="(answer, index) in selectedValue(questionKey(question.qid, subQuestion.code))" :key="index" class="mb-2">
                          <b-icon icon="check"></b-icon>
													<span v-html="answer"></span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <ValidationProvider
                    :key="subQuestion.id"
                    :ref="`mobileProvider${getIndex(subQuestion._id)}`"
                    :rules="validationRules"
                    class="card-content"
                    tag="div"
                  >
                    <div class="content">
                      <b-field
                        :key="answer.id"
                        class="mb-4"
                        v-for="answer in question.answers"
                      >
                        <b-checkbox
                          v-model="selected[subQuestion.code]"
                          @input="
                            onInput(question.qid, subQuestion.code, answer, $event)
                          "
                          :disabled="shouldDisable(questionKey(question.qid, subQuestion.code, answer.code))"
                          :native-value="answer.code"
                        >
                          <span v-html="answer.answer[lang]"></span>
                        </b-checkbox>
                      </b-field>
                    </div>
                  </ValidationProvider>
                </b-collapse>
            </div>
          </template>
          <template v-else>
              <b-collapse
                :ref="`collapse${index}`"
                :key="`collapse-${index}`"
                :open="isOpen === index"
                @open="openCollapse(index)"
                animation="slide"
                class="card mb-2"
                v-for="(subQuestion, index) in question.sub_questions"
              >
                <template #trigger="props">
                  <div
                    :class="{ active: props.open, 'has-error': hasError(index) }"
                    class="card-header"
                    role="button"
                  >
                    <div class="card-header-control">
                      <p
                        class="card-header-title"
                        v-html="subQuestion.question[lang]"
                      ></p>
                      <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                      </a>
                    </div>
                    <div
                      class="selected-option"
                      v-if="!props.open"
                    >
                      <div v-for="(answer, index) in selectedValue(questionKey(question.qid, subQuestion.code))" :key="index" class="mb-2">
                        <b-icon icon="check"></b-icon>
                        <span v-html="answer"></span>
                      </div>
                    </div>
                  </div>
                </template>
                <ValidationProvider
                  :key="subQuestion.id"
                  :ref="`mobileProvider${index}`"
                  :rules="validationRules"
                  class="card-content"
                  tag="div"
                >
                  <div class="content">
                    <b-field
                      :key="answer.id"
                      class="mb-4"
                      v-for="answer in question.answers"
                    >
                      <b-checkbox
                        v-model="selected[subQuestion.code]"
                        @input="
                          onInput(question.qid, subQuestion.code, answer, $event)
                        "
                        :disabled="shouldDisable(questionKey(question.qid, subQuestion.code, answer.code))"
                        :native-value="answer.code"
                      >
                        <span v-html="answer.answer[lang]"></span>
                      </b-checkbox>
                    </b-field>
                  </div>
                </ValidationProvider>
              </b-collapse>
          </template>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import _ from 'lodash'
import { QuestionMixin } from "@/mixins/question";
import { BlockRandomizationMixin } from "@/mixins/block-randomization";

export default {
  name: "MultipleAnswerMatrix",
  mixins: [QuestionMixin, BlockRandomizationMixin],
  data() {
    return {
      isOpen: 0,
      showMobileView: false,
      selected: {},
      disabled: [],
    };
  },
  methods: {
    shouldDisable(answer){
      if(this.loading) return true;
      return this.disabled.includes(answer);
    },
    colWidth(index) {
      if (index === 1) return '25%'
      return `${Math.round(75 / this.cols)}%`;
    },
    onInput(qid, sqcode, answer, selected) {
      // this.isOpen++;
      const isChecked = selected.includes(answer.code)
      const value = isChecked ? "1" : null;

      const changes = { [this.questionKey(qid, sqcode, answer.code)]: value };
      this.$emit("input", changes);
    },
    hasError(index) {
      let provider = this.$refs[`mobileProvider${index}`];
      if (!provider) return false;
      return provider[0].errors.length >= 1;
    },
    selectedValue(code) {
      let result = []
      const self = this
      _.forEach(Object.keys(this.value).filter(key => key.includes(code) && this.value[key] == 1), function (qCode) {
        const answer = self.question.answers.find((answer) => {
          return answer.code === qCode.split('_')[2];
        })
        if (!answer) return;
        result.push(answer.answer[self.lang])
      })

      return result
    },
    openCollapse(index) {
      this.isOpen = index;
      const el = this.$refs[`collapse${index}`];
      if (el) {
        window.setTimeout(function () {
          el[0].$el.scrollIntoView({ behavior: "smooth", block: "center"});
        }, 200)
      }
    },
    getIndex(id) {
      return this.flattenCategory.findIndex(item => item._id === id);
    },
    mapSelected() {
      return this.question.sub_questions
        .reduce(
          (obj, item) => Object.assign(obj, { [item.code]:
            Object.keys(this.value)
              .filter(
                (key) =>
                  key.split("_")[0] === `${this.question.qid}` &&
                  key.split("_")[1] === `${item.code}` &&
                  this.value[key] === "1"
              )
              .map((qid) => qid.split("_")[2])
          }), {})
    },
  },
  computed: {
    cols() {
      return this.question.answers.length + 1;
    },
    flattenCategory() {
      return this.categories?.flatMap(item => item.sub_questions);
    },
  },
  mounted() {
    this.$emit("mounted", "is-10");
  },
  created() {
    setTimeout(() => {
      this.selected = this.mapSelected();
    }, 250);
  }
};
</script>
<style lang="scss">
.multiple-answer-matrix {
  color: #595959;

  .b-radio.radio {
    margin-right: 0;

    .control-label {
      @include from($desktop) {
        // display: none;
      }
    }
  }

  table {
    td:not(:first-child) {
      text-align: center;
    }

    tr:not(:first-child) {
      td:not(:first-child) {
        vertical-align: middle;
      }
    }

    tr:not(.border-0):not(.category-title) {
      border-top: 1px solid #dbdbdb;
    }

    tr:last-child td {
      border-bottom: none;
    }

    &.block-randomization tr {
      &:last-child {
        border-bottom: 1px solid #dbdbdb;
      }

      &.border-0,
      &.border-0 td {
        border-bottom: none !important;
      }
    }
  }

  .is-mobile {
    .card {
      box-shadow: none;

      .card-header {
        border-bottom: 1px solid $gray-4;
        box-shadow: none;
        flex-direction: column;
        border-radius: 0;

        &.has-error {
          color: $red;
          border-bottom: 1px solid $red;

          .card-header-title,
          .icon {
            color: $red;
          }
        }

        &.active {
          border: none;
        }

        .card-header-control {
          display: flex;
        }

        .card-header-title {
          color: $gray-10;
					display: block;
          font-weight: 500;
          font-size: 1rem;
          line-height: 22px;
          padding: 0.75rem 0;
        }

        .card-header-icon {
          padding: 0.75rem 0;
        }

        .selected-option {
          margin-bottom: 1rem;
          color: $primary;
        }
      }
    }

    .card-content {
      margin-top: 1.5rem;

      .content {
        .field {
          border: 1px solid #BDBDBD;
          border-radius: 4px;
          padding: 1rem;
          display: flex;
        }
      }
    }

    .category-title {
      padding-bottom: 5px;
      border-bottom: 1px solid #dbdbdb;
    }
  }

  .b-checkbox.checkbox {
    .check {
      border: 2px solid #8CCABB !important;
    }
  }
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  margin-left: -20px;
  z-index: 999;
}
</style>
